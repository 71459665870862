<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Kode"
              v-model="filter.project_code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Meeting Agenda</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Meeting Agenda"
              v-model="filter.subject"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Project Nickname"
              v-model="filter.project_nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">MoM Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="MoM Number"
              v-model="filter.mom_code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Meeting Dates</label>
            <b-input-group>
              <input
                class="form-control"
                type="text"
                v-model="filter.date"
                id="example-date-input"
                placeholder="YYYY-MM-DD"
              />
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  v-model="filter.date"
                  :no-flip="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="id"
                  reset-button
                  reset-value=""
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="statusOptions"
              size="md"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getMeetings(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button class="btn btn-success btn-md" @click="toCreatePage()">
          <i class="flaticon-add"></i> Add Meeting
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="meetings">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Project Code</th>
              <th class="pl-7">Nickname</th>
              <th class="pl-7">Meeting Date</th>
              <th class="pl-7">Meeting Agenda</th>
              <th class="pl-7">MOM Number</th>
              <th class="pl-7">Status</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project ? item.project.code : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project ? item.project.nickname : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(item.date).isValid() ? 
                    moment(item.date).format("DD-MM-YYYY") : "" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.subject }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.status }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toUpdatePage(item.id)"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                  @click="toDetailPage(item.id)"
                >
                  <i class="flaticon-folder-1"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Meeting Will Deleted</label>
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteMeeting"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_MEETING,
  GET_MEETING,
} from "../../../core/services/store/meeting.module";
// import moment from "moment";

export default {
  components: { CompleteTable },

  data() {
    return {
      filter: {
        project_code: null,
        project_nickname: null,
        date: null,
        subject: null,
        mom_code: null,
        status: null,
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        name: null,
      },
      isCreate: true,
      statusOptions: [
        {
          text: "Choose Status",
          value: null,
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Close",
          value: "close",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.meeting.loading,
      meetings: (state) => state.meeting.meetings?.data,
    }),
  },

  methods: {
    getMeetings(filter = false) {
      if (!arrayMoreThanOne(this.meetings) || filter) {
        this.$store.dispatch(GET_MEETING, {
          params: {
            ...this.filter,
          },
        });
      }
    },

    deleteMeeting() {
      this.$store
        .dispatch(DELETE_MEETING, {
          code: this.currentItem?.code,
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    toCreatePage() {
      this.$router.push({
        name: "meeting-create",
      });
    },

    toUpdatePage(id) {
      this.$router.push({
        name: "meeting-update",
        params: {
          id,
        },
      });
    },

    toDetailPage(id) {
      this.$router.push({
        name: "meeting-detail",
        params: {
          id,
        },
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Meeting" }]);

    this.getMeetings();
  },
};
</script>
